import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { Login } from './components/login/Login';
import { PocList } from './components/pocList/PocList';
import PrivateWrapper from './PrivateRoute';
import Header from './components/header/Header';



const Routings = () => {

    const location = useLocation();
    const isLogin = location.pathname === '/login';
    const authendicated = localStorage.getItem("poclist-authenticated")

    return (
      <div className='main-body'>
       {!isLogin && authendicated && <Header />}
        <Routes>
          <Route
            path="/"
            element={
              authendicated ? (
                <Navigate to="/poclist" />
              ) : (
                <Navigate to="/login" />
              )
            }
          ></Route>
          <Route
            path="/login"
            element={authendicated ? <Navigate to={"/"} /> : <Login />}
          ></Route>
          <Route
            path="/poclist"
            element={
              <PrivateWrapper>
                <PocList />
              </PrivateWrapper>
            }
          ></Route>
          <Route
            path="/productlist"
            element={
              <PrivateWrapper>
                <PocList />
              </PrivateWrapper>
            }
          ></Route>
        </Routes>
      </div>
    );
}

export default Routings