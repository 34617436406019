// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-body-BG {
  background: linear-gradient(180deg, black 0%, #A721CA 56%, #2B8CE8 100%);
  min-height: 100vh;
}

.main-section-body {
  flex: 1 1;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  display: flex;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
}

.ant-table-thead {
  font-family: "Poppins-Regular", sans-serif;
  font-size: 13px;
  font-weight: 200;
}

.btn-viewcred {
  font-family: "Poppins-Regular", sans-serif;
}

.btn-viewcred:hover {
  color: #2F54EB;
}

.modal-logo {
  margin: 10px 10px;
  width: 35px;
  margin-right: 18px;
}

.name-icon-cell {
  display: flex;
  align-items: center;
}

.name-icon-cell img {
  width: 50px;
  margin-right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/pocList/PocList.scss"],"names":[],"mappings":"AAAA;EACI,wEAAA;EACA,iBAAA;AACJ;;AAEA;EACI,SAAA;EACA,oBAAA;EACA,kBAAA;EACA,mBAAA;EACA,iBAAA;EACA,sBAAA;EACA,2BAAA;EACA,mBAAA;EACA,QAAA;EACA,aAAA;EACA,eAAA;EACA,WAAA;EACA,cAAA;AACJ;;AAEA;EACI,0CAAA;EACA,eAAA;EACA,gBAAA;AACJ;;AAEA;EACI,0CAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AACA;EACI,iBAAA;EACA,WAAA;EACA,kBAAA;AAEJ;;AACA;EACI,aAAA;EACA,mBAAA;AAEJ;;AACA;EACI,WAAA;EACA,kBAAA;AAEJ","sourcesContent":[".main-body-BG {\n    background: linear-gradient(180deg, black 0%, #A721CA 56%, #2B8CE8 100%);\n    min-height: 100vh;\n}\n\n.main-section-body {\n    flex: 1 1 0;\n    padding-bottom: 40px;\n    padding-left: 40px;\n    padding-right: 40px;\n    padding-top: 50px;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n    gap: 4px;\n    display: flex;\n    max-width: 100%;\n    width: 100%;\n    margin: 0 auto;\n}\n\n.ant-table-thead {\n    font-family: 'Poppins-Regular', sans-serif;\n    font-size: 13px;\n    font-weight: 200;\n}\n\n.btn-viewcred {\n    font-family: 'Poppins-Regular', sans-serif;\n}\n\n.btn-viewcred:hover {\n    color: #2F54EB;\n}\n.modal-logo {\n    margin: 10px 10px;\n    width: 35px;\n    margin-right: 18px;\n}\n\n.name-icon-cell{\n    display: flex;\n    align-items: center;\n}\n\n.name-icon-cell img {\n    width: 50px;\n    margin-right:20px ;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
