import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import logo from "../../assets/images/logo_dark.png";
import "../../assets/css/main.css";
import "../../assets/css/util.css";
import "../../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css";
import "../../assets/vendor/animate/animate.css";
import "../../assets/vendor/css-hamburgers/hamburgers.min.css";
import "../../assets/vendor/select2/select2.min.css";
import "./Login.scss";

export const Login = () => {
  const navigate = useNavigate();
  const [loginDetails, setLoginDetails] = useState({
    username: "",
    password: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      loginDetails.username === "admin@innoflexion.com" &&
      loginDetails.password === "innoflexion123"
    ) {
      localStorage.setItem("poclist-authenticated", Math.random());
      navigate("/");
    } else {
      message.error("Invalid username or password");
    }
  };

  return (
    <div className="limiter">
      <div
        className="container-login100 "
        style={{
          background:
            "linear-gradient(180deg, black 0%, #A721CA 56%, #2B8CE8 100%)",
        }}
      >
        <div className="wrap-login100">
          <div className="login100-pic js-tilt" data-tilt>
            <img src={logo} alt="IMG" />
            <p className="head-text"> Navigation Portal</p>
          </div>

          <form className="login100-form validate-form" onSubmit={handleSubmit}>
            <div
              className="wrap-input100 validate-input"
              data-validate="Valid email is required: ex@abc.xyz"
            >
              <input
                className="input100"
                type="text"
                value={loginDetails.username}
                onChange={(e) =>
                  setLoginDetails((prev) => ({
                    ...prev,
                    username: e.target.value,
                  }))
                }
                placeholder="Username"
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <i className="fa fa-envelope" aria-hidden="true"></i>
              </span>
            </div>
            <div
              className="wrap-input100 validate-input"
              data-validate="Password is required"
            >
              <input
                className="input100"
                type="password"
                value={loginDetails.password}
                onChange={(e) =>
                  setLoginDetails((prev) => ({
                    ...prev,
                    password: e.target.value,
                  }))
                }
                placeholder="Password"
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <i className="fa fa-lock" aria-hidden="true"></i>
              </span>
            </div>
            <div className="container-login100-form-btn">
              <button className="login100-form-btn">Login</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
