// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.head-text {
  width: 110%;
  font-size: larger;
  letter-spacing: 9.5px;
  font-weight: 600;
  color: rgb(26, 18, 18);
}`, "",{"version":3,"sources":["webpack://./src/components/login/Login.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,iBAAA;EACA,qBAAA;EACA,gBAAA;EACA,sBAAA;AACJ","sourcesContent":[".head-text {\n    width: 110%;\n    font-size: larger;\n    letter-spacing: 9.5px;\n    font-weight: 600;\n    color: rgb(26, 18, 18);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
