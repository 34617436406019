import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./PocList.scss";
import { Table, ConfigProvider } from "antd";
import { render } from "@testing-library/react";
import link_icon from "../../assets/images/link_icon.svg";
import flexblok_png from "../../assets/images/flexblok.png"
import microgrid_png from "../../assets/images/microgrid.png";
import nicklpass_png from "../../assets/images/nicklpass.png";
import pharma_png from "../../assets/images/pharma.png";

export const PocList = () => {
  const [productname, setProductName] = useState();
  const [productLogoURL, setProductLogoURL] = useState();
  const [modeldata, setModeldata] = useState([
    {
      id: "",
      role: "",
      username: "",
      password: "",
    },
  ]);

  const location = useLocation();

  const [productdetail, setProductDetail] = useState([
    {
      id: 1,
      name: "Admin Management Console",
      type: "Product",
      logoURL: flexblok_png,
      frontend: "http://admin.flexblok.io:3002",
      backend: "http://52.11.201.231:3000",
      demo: "https://drive.google.com/file/d/17uTf4PMB0-d154S6FM5Zqe6kzfnD8DmJ/view?usp=drive_link",
      credentials: [
        {
          id: 1,
          role: "Admin",
          username: "innoflexion",
          password: "innoflexion123",
        },
      ],
    },
    {
      id: 2,
      name: "Customer Portal",
      type: "Product",
      logoURL: flexblok_png,
      frontend: "http://user.flexblok.io:3006",
      backend: "http://18.237.89.150:4001",
      demo: "https://drive.google.com/file/d/1YsOk0Jfuh6sI3h6IOw9AVjQ6kJFSx1C7/view?usp=drive_link",
      credentials: [
        {
          id: 1,
          role: "Admin",
          username: "woxame5020@rc3s.com",
          password: "12345678",
        },
      ],
    },
    {
      id: 3,
      name: "MicroGrid",
      type: "POC",
      logoURL: microgrid_png,
      frontend: "http://35.86.59.52:3003",
      backend: "http://18.237.89.150:4000",
      demo: "https://drive.google.com/file/d/1XEINtrIUM3nimpb9cnVM1VrQiTHktOX6/view?usp=drive_link",
      credentials: [
        {
          id: 1,
          role: "Admin",
          username: "william@microgrid.com",
          password: "microgrid123",
        },
        {
          id: 2,
          role: "Seller",
          username: "michael@microgrid.com",
          password: "microgrid123",
        },
        {
          id: 3,
          role: "User",
          username: "jessica@gmail.com",
          password: "microgrid123",
        },
      ],
    },
    {
      id: 4,
      name: "Cardinal",
      type: "POC",
      frontend: "http://cardinal.flexblok.io:3001",
      backend: "http://18.237.89.150:4000",
      demo: "https://drive.google.com/file/d/192uRK5z7FjfJJ3OZA1t6Ref_A8E2Ukmc/view?usp=drive_link",
      credentials: [
        {
          id: 1,
          role: "Admin",
          username: "john@cardinal.com",
          password: "Random Password",
        },
        {
          id: 2,
          role: "User",
          username: "robert@cardinal.com",
          password: "Random Password",
        },
      ],
    },
    {
      id: 5,
      name: "NicklPass",
      type: "POC",
      logoURL: nicklpass_png,
      frontend: "http://nicklpass.flexblok.io:3004",
      backend: "http://18.237.89.150:4000",
      demo: "https://drive.google.com/file/d/1-Hct9TCnD7HFCLJ7nuJCz-rFH1m3tN5a/view?usp=drive_link",
      credentials: [
        {
          id: 1,
          role: "Admin",
          username: "admin@nicklpass.com",
          password: "Random Password",
        },
      ],
    },
    {
      id: 6,
      name: "Flexblok DTS",
      type: "POC",
      logoURL: flexblok_png,
      frontend: "http://dts.flexblok.io:3007",
      backend: "http://18.237.89.150:4000",
      credentials: [
        {
          id: 1,
          role: "Admin",
          username: "admin",
          password: "innoflexion123",
        },
      ],
    },
    {
      id: 7,
      name: "Flexblok NFT Private",
      type: "POC",
      logoURL: flexblok_png,
      frontend: "http://nft.flexblok.io:3009",
      backend: "http://18.237.89.150:4000",
      credentials: [
        {
          id: 1,
          role: "",
          username: "robert@gmail.com",
          password: "12345678",
        },
      ],
    },
    {
      id: 8,
      name: "Flexblok NFT Public",
      type: "POC",
      logoURL: flexblok_png,
      frontend: "http://nft.flexblok.io:3010",
      backend: "http://18.237.89.150:4000",
      credentials: [
        {
          id: 1,
          role: "",
          username: "robert@gmail.com",
          password: "12345678",
        },
      ],
    },
    {
      id: 9,
      name: "Flexblok DID",
      type: "POC",
      logoURL: flexblok_png,
      frontend: "http://35.86.59.52:4005",
      backend: "http://52.10.217.150:4002",
      demo: "https://drive.google.com/file/d/1Dsz1FMfArJfWIx1-03Ek-8fZZoJlzZ0q/view?usp=drive_link",
      credentials: [
        {
          id: 1,
          role: "University",
          username: "university@gmail.com",
          password: "1234567",
        },
        {
          id: 2,
          role: "Employer",
          username: "innoflexion@gmail.com",
          password: "1234567",
        },
        {
          id: 3,
          role: "Student",
          username: "shinya@gmail.com",
          password: "12345678",
        },
      ],
    },
  ]);

  const originalData = [
    {
      id: 1,
      name: "Admin Management Console",
      type: "Product",
      logoURL: flexblok_png,
      frontend: "https://admin.flexblok.io",
      backend: "http://52.11.201.231:3000",
      demo: "https://drive.google.com/file/d/17uTf4PMB0-d154S6FM5Zqe6kzfnD8DmJ/view?usp=drive_link",
      credentials: [
        {
          id: 1,
          role: "Admin",
          username: "innoflexion",
          password: "innoflexion123",
        },
      ],
    },
    {
      id: 2,
      name: "Customer Portal",
      type: "Product",
      logoURL: flexblok_png,
      frontend: "https://user.flexblok.io",
      backend: "http://18.237.89.150:4001",
      demo: "https://drive.google.com/file/d/1YsOk0Jfuh6sI3h6IOw9AVjQ6kJFSx1C7/view?usp=drive_link",
      credentials: [
        {
          id: 1,
          role: "Admin",
          username: "woxame5020@rc3s.com",
          password: "12345678",
        },
      ],
    },
    {
      id: 3,
      name: "MicroGrid",
      type: "POC",
      logoURL: microgrid_png,
      frontend: "https://apps.flexblok.io/microgrid",
      backend: "http://18.237.89.150:4000",
      demo: "https://drive.google.com/file/d/1XEINtrIUM3nimpb9cnVM1VrQiTHktOX6/view?usp=drive_link",
      credentials: [
        {
          id: 1,
          role: "Admin",
          username: "william@microgrid.com",
          password: "microgrid123",
        },
        {
          id: 2,
          role: "Seller",
          username: "michael@microgrid.com",
          password: "microgrid123",
        },
        {
          id: 3,
          role: "User",
          username: "jessica@gmail.com",
          password: "microgrid123",
        },
      ],
    },
    {
      id: 4,
      name: "Pharma Supply Chain",
      type: "POC",
      logoURL: pharma_png,
      frontend: "https://apps.flexblok.io/pharmasupplychain",
      backend: "http://18.237.89.150:4000",
      demo: "https://drive.google.com/file/d/192uRK5z7FjfJJ3OZA1t6Ref_A8E2Ukmc/view?usp=drive_link",
      credentials: [
        {
          id: 1,
          role: "Admin",
          username: "john@cardinal.com",
          password: "Random Password",
        },
        {
          id: 2,
          role: "User",
          username: "robert@cardinal.com",
          password: "Random Password",
        },
      ],
    },
    {
      id: 5,
      name: "NicklPass",
      type: "POC",
      logoURL: nicklpass_png,
      frontend: "https://apps.flexblok.io/nic",
      backend: "http://18.237.89.150:4000",
      demo: "https://drive.google.com/file/d/1-Hct9TCnD7HFCLJ7nuJCz-rFH1m3tN5a/view?usp=drive_link",
      credentials: [
        {
          id: 1,
          role: "Admin",
          username: "admin@nicklpass.com",
          password: "Random Password",
        },
      ],
    },
    {
      id: 6,
      name: "DTS",
      type: "POC",
      logoURL: flexblok_png,
      frontend: "https://apps.flexblok.io/dts",
      backend: "http://18.237.89.150:4000",
      credentials: [
        {
          id: 1,
          role: "Admin",
          username: "admin",
          password: "innoflexion123",
        },
      ],
    },
    {
      id: 7,
      name: "NFT",
      type: "POC",
      logoURL: flexblok_png,
      frontend: "https://apps.flexblok.io/nft",
      backend: "http://18.237.89.150:4000",
      credentials: [
        {
          id: 1,
          role: "",
          username: "robert@gmail.com",
          password: "12345678",
        },
      ],
    },
    // {
    //     "id": 8,
    //     "name": "Flexblok NFT Public",
    //     "type": "POC",
    //     "frontend": "http://nft.flexblok.io:3010",
    //     "backend": "http://18.237.89.150:4000",
    //     "credentials": [{
    //         "id": 1,
    //         "role": "",
    //         "username": "robert@gmail.com",
    //         "password": "12345678"
    //     }
    //     ]
    // },
    {
      id: 9,
      name: "DID",
      type: "POC",
      logoURL: flexblok_png,
      frontend: "https://apps.flexblok.io/did",
      backend: "http://52.10.217.150:4002",
      demo: "https://drive.google.com/file/d/1Gcs7lc1XAk4DiYptUE-wYFrL70-GNeqC/view?usp=drive_link",
      credentials: [
        {
          id: 1,
          role: "University",
          username: "admin@bangaloreuniversity.com",
          password: "12345678",
        },
        {
          id: 2,
          role: "University",
          username: "admin@mit.com",
          password: "12345678",
        },
        {
          id: 3,
          role: "Employer",
          username: "employer@innoflexion.com",
          password: "12345678",
        },
        {
          id: 4,
          role: "Employer",
          username: "employer@abctech.com",
          password: "12345678",
        },
        {
          id: 5,
          role: "Student",
          username: "rithis@gmail.com",
          password: "12345678",
        },
      ],
    },
  ];

  useEffect(() => {
    if (location.pathname === "/productlist") {
      let temp = originalData;
      temp = temp.filter((el) => {
        if (location.pathname === "/productlist") {
          return el.type === "Product";
        }
        return false;
      });
      setProductDetail(temp);
    } else {
      let temp = originalData;
      temp = temp.filter((el) => {
        if (location.pathname === "/poclist") {
          return el.type === "POC";
        }
        return false;
      });
      setProductDetail(temp);
    }
  }, [location]);

  const showDetail = (id) => {
    setProductName(productdetail.find((el) => el.id === id).name);
    setProductLogoURL(productdetail.find((el) => el.id === id).logoURL);
    setModeldata(productdetail.find((el) => el.id === id).credentials);
  };

  const tableColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "450px",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <div className="name-icon-cell">
          <img src={record.avatar} alt="icon" />
          <a>{text}</a>
        </div>
      ),
    },
    {
      title: "Frontend URL",
      dataIndex: "fronturl",
      key: "age",
      width: "450px",
      render: (fronturl) => <a href={fronturl} target="_blank" rel="noreferrer">{fronturl}</a>,
    },
    {
      title: "View",
      dataIndex: "viewcredId",
      key: "viewcred",
      width: "250px",
      render: (viewcredId) => (
        <button
          className="btn-viewcred"
          onClick={(e) => showDetail(viewcredId)}
          data-toggle="modal"
          data-target="#myModal"
        >
          View Credentials
        </button>
      ),
    },
    {
      title: "Demo",
      dataIndex: "demoUrl",
      key: "demoUrl",
      width: "120px",
      render: (demoUrl) => (
        <a href={demoUrl} target="_blank" rel="noreferrer">
          {" "}
          <img src={link_icon} alt="icon" />{" "}
        </a>
      ),
    },
  ];

  const tableData = productdetail.map((item) => ({
    key: item.id.toString(),
    name: item.name,
    fronturl: item.frontend,
    demoUrl: item.demo,
    avatar: item.logoURL,
    viewcredId: item.id,
  }));
  console.log(tableData)

  return (
    <>
      <div className="main-section-body">
        <ConfigProvider theme={{components:{Table:{headerColor: "#8E8E8E"}}}} >
          <Table columns={tableColumns} dataSource={tableData} size="large" />
        </ConfigProvider>

        <div className="modal show" id="myModal">
          <div
            className="modal-dialog modal-dialog-centered modal-lg show"
            style={{ width: "700px" }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <img className="modal-logo" src={productLogoURL} />
                <h4 className="modal-title">{productname}</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <table className="table table-striped table-bordered table-sm">
                  <thead className="thead-light">
                    <tr>
                      <th>Sl No.</th>
                      <th>Role</th>
                      <th>Username</th>
                      <th>Password</th>
                    </tr>
                  </thead>
                  <tbody>
                    {modeldata.map((credentials, index) => (
                      <tr key={index}>
                        <td>{credentials.id}</td>
                        <td>{credentials.role}</td>
                        <td>{credentials.username}</td>
                        <td>{credentials.password}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn"
                  data-dismiss="modal"
                  style={{
                    background: "#2B8CE8",
                    color: "#FFF",
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
