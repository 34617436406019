import React from "react";
import "./Header.scss";
import { Dropdown, Layout, Menu, theme } from "antd";
import Logo from "../../assets/images/logo_white.png";
import { useNavigate, useLocation } from "react-router-dom";
import product_button from "../../assets/images/product_button.svg";
import poc_button from "../../assets/images/poc_button.svg";
import drop_down from "../../assets/images/drop_down.svg";
import user_avatar from "../../assets/images/innoflexion.png"

const { Header } = Layout;

const items1 = ["Launch"].map((key) => ({
  key,
  label: `${key}`,
}));

const App = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();
  const location = useLocation()
  
  const menu = () => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            localStorage.removeItem("poclist-authenticated");
            navigate("/login");
          }}
        >
          Log Out
        </Menu.Item>
      </Menu>
    );
  };
  return (
    <Header className="content-header  ">
      <div className="logo-wrapper ">
        <div className="logo-icon">
          <img className="icon-img" src={Logo} alt="logo" />
        </div>
      </div>
      <div
        className={`header-buttons ${
          location.pathname === "/productlist" ? "active" : ""
        }`}
        onClick={() => navigate("/productlist")}
      >
        <div className="button-wrapper">
          <div className="svg-Wrapper">
            <img src={product_button} alt="product-button" />
          </div>
        </div>
        <div className="button-title cp">Products</div>
      </div>
      <div
        className={`header-buttons ${
          location.pathname === "/poclist" ? "active" : ""
        }`}
        onClick={() => navigate("/poclist")}
      >
        <div className="button-wrapper">
          <div className="svg-Wrapper">
            <img src={poc_button} alt="poc-button" />
          </div>
        </div>
        <div className="button-title cp">POCs</div>
      </div>

      <div className="user-profile ">
        <Dropdown overlay={menu()}>
          <div className="dropdown-container">
            <img className="user-avatar" src={user_avatar} />
            <div className="Frame">
              <div className="user-name">Innoflexion</div>
              <div className="user-email">admin@innoflexion.com</div>
            </div>
            <div className="dropdown-svg-wrapper">
              <img src={drop_down} alt="" />
            </div>
          </div>
        </Dropdown>
      </div>
    </Header>
  );
};
export default App;
